import { Suspense } from 'react';
import { Heading } from 'react-aria-components';
import { ALLOWED_TAGS, AuditLogEntryDetails, EntryApp, EntryAvatar, EntryTitle } from '@gonfalon/audit-log';
import { baseUri } from '@gonfalon/constants';
import { useAccountContext, useProjectContext } from '@gonfalon/context';
import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { useSuspenseAuditLogEntry } from '@gonfalon/rest-api';
import { Dialog, IconButton, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import { Markdown } from '@launchpad-ui/core';
import parse from 'html-react-parser';

import { Skeleton } from '../internal/Skeleton';

import styles from './ChangeHistory.module.css';

// these access actions do not have a diff, so we don't want to show the diff tooltip
const actionsWithNoDetails = ['applyApprovalRequest', 'reviewApprovalRequest', 'createApprovalRequest'];

export const ChangeHistoryItem = ({ id }: { id: string }) => {
  const { data } = useSuspenseAuditLogEntry({ id });
  const { account } = useAccountContext();
  const projectContext = useProjectContext({ optional: true });
  const projectName = projectContext?.project?.name ?? 'Project';

  const renderProject = () => {
    if (!projectName) {
      return;
    }
    return projectName && <span> &mdash; {projectName}</span>;
  };

  const renderApp = () => {
    const app = data.app;
    if (!app) {
      return null;
    }

    return (
      <p className="u-very-subtle u-mt-xs">
        <EntryApp app={app} />
      </p>
    );
  };

  const renderComment = () => {
    if (data.comment) {
      return <div className={styles.comment}>{data.comment}</div>;
    }
  };

  const hasDetailedChanges = !data.accesses.some(({ action }) => {
    if (!action) {
      return false;
    }
    return actionsWithNoDetails.includes(action);
  });

  return (
    <Dialog className={styles.dialog}>
      {({ close }) => (
        <Suspense fallback={<Skeleton />}>
          <>
            <div slot="header">
              <Heading slot="title">
                <EntryAvatar entry={data} size="large" className="u-mr-m" />
                <EntryTitle entry={data} organizationName={account.organization} />
              </Heading>
              <IconButton aria-label="close" icon="cancel" size="small" variant="minimal" onPress={close} />
            </div>
            <div slot="body">
              <div className="fs-exclude" data-test-id="audit-log-item-viewer">
                <section>
                  <p className="u-very-subtle">
                    <Time dateFormat={DateFormat.MMM_D_YYYY_H_MM_A} datetime={data.date} />
                    {renderProject()}
                  </p>
                  {data.token && (
                    <p className="u-very-subtle u-mt-xs">
                      Authenticated using the token "{data.token.name}" (…{data.token.ending})
                    </p>
                  )}
                  {data.app && renderApp()}
                  {data.comment && renderComment()}
                  {data.description && (
                    <div>
                      <p className="u-mt-m">Changes:</p>
                      <Markdown
                        source={data.description}
                        allowedTags={ALLOWED_TAGS}
                        baseUri={baseUri()}
                        className="u-fs-sm"
                      >
                        {(parsedMarkdown) =>
                          parse(parsedMarkdown, {
                            replace: (node) => {
                              if (
                                node.nodeType === 3 &&
                                'data' in node &&
                                typeof node.data === 'string' &&
                                node.data.includes('...')
                              ) {
                                const parts = node.data.split('...');
                                return (
                                  <>
                                    {parts.map((part, index) => (
                                      <span key={index}>
                                        {part}
                                        {index < parts.length - 1 && (
                                          <>
                                            ...
                                            <TooltipTrigger>
                                              <IconButton
                                                icon="info-circle"
                                                size="small"
                                                variant="minimal"
                                                aria-label="View full change details"
                                              />
                                              <Tooltip>
                                                {hasDetailedChanges
                                                  ? 'Some of the output was truncated, check diff for full changes'
                                                  : 'Some of the output was truncated'}
                                              </Tooltip>
                                            </TooltipTrigger>
                                          </>
                                        )}
                                      </span>
                                    ))}
                                  </>
                                );
                              }
                            },
                          })
                        }
                      </Markdown>
                    </div>
                  )}
                  <AuditLogEntryDetails id={id} />
                </section>
              </div>
            </div>
          </>
        </Suspense>
      )}
    </Dialog>
  );
};
